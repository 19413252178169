<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>Rankingi</v-toolbar-title>
    </v-toolbar>

    <!-- event -->
    <v-toolbar-title class="body-1 ml-3 mt-3 mr-3">Konkurencja</v-toolbar-title>
    <v-chip-group column mandatory v-model="event">
      <v-chip v-for="(e, index) in events" :key="e" outlined
              :color="index === event ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRanking(index, category, type)">
        <v-icon>{{ $utils.events.getEventIcon(e) }}</v-icon>
      </v-chip>
    </v-chip-group>

    <!-- category -->
    <v-toolbar-title class="body-1 ml-3 mt-3 mr-3">Kategoria</v-toolbar-title>
    <v-chip-group column mandatory v-model="category">
      <v-chip v-for="(c, index) in categories" :key="index" outlined
              :color="index === category ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRanking(event, index, type)">
        {{ getCategoryName(c) }}
      </v-chip>
    </v-chip-group>

    <!-- type -->
    <v-toolbar-title class="body-1 ml-3 mt-3 mr-3">Typ</v-toolbar-title>
    <v-chip-group column mandatory v-model="type">
      <v-chip v-for="(t, index) in types" :key="index" outlined
              :color="index === type ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRanking(event, category, index)">
        {{ t === 's' ? 'Najlepsze' : 'Średnia' }}
      </v-chip>
    </v-chip-group>

    <v-divider/>

    <!-- top pagination -->
    <!--<v-pagination v-model="page" :length="pages" total-visible="10" color="green" class="mt-5 mb-n3"/>-->

    <!-- rankings -->
    <v-simple-table dense class="pa-3" v-if="tempNoDataForGLS === false">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-right">#</th>
          <th class="text-left">Zawodnik</th>
          <th class="text-center">Wynik</th>
          <th class="text-left">Zawody</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(r, index) in ranking" :key="index">
          <td class="text-right">{{ r[0] }}</td>
          <td class="text-left">{{ r[2] }}</td>
          <td class="text-center">{{ $utils.results.parseResult(parseInt(r[3]), event) }}</td>
          <td class="text-left">{{ r[5] }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- bottom pagination -->
    <v-pagination v-if="pages !== 1" v-model="page" :length="pages" total-visible="10" color="primary"
                  class="mt-n2 mb-5" @click="console.log('xd')"/>

    <v-alert v-if="tempNoDataForGLS === true" type="info" class="ma-3" color="primary" outlined>
      <span class="font-weight-bold">Informacja:&nbsp;</span>
      <span>Rankingi GLS są tymczasowo niedostępne.</span>
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Rankings',
  data: () => ({
    event: 0, events: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    category: 0, categories: ['overall', '8', '10', '12', '14', '16', 'women', 'parents'],
    type: 0, types: ['s', 'a'],
    page: 1, pages: 1,
    ranking: [],
    tempNoDataForGLS: false,
  }),
  created() {
    this.loadPage();
  },
  methods: {
    getCategoryName(category) {
      if (category === 'overall') return 'Open';
      if (category === '8') return 'Do lat 8';
      if (category === '10') return 'Do lat 10';
      if (category === '12') return 'Do lat 12';
      if (category === '14') return 'Do lat 14';
      if (category === '16') return 'Do lat 16';
      if (category === 'k') return 'Kobiety';
      if (category === 'r') return 'Rodzice';
      if (category === 'women') return 'Kobiety';
      if (category === 'parents') return 'Rodzice';
      return '';
    },
    loadRanking(event, category, type) {
      this.event = event;
      this.category = category;
      this.type = type;
      this.page = 1;
      this.loadPage();
    },
    loadPage() {
      if (this.$env.APP_MODE !== 'ppo')
        return this.tempNoDataForGLS = true;
      let path = this.categories[this.category] + '_'
          + this.$utils.events.EVENT_ICONS[this.event] + '_'
          + this.types[this.type] + '_' + this.page + '.csv?v=3';
      this.$http.get(window.location.origin + '/data/rankings/' + path + '?v=' + (new Date().getTime()).toString()).then((res) => {
        let records = ('' + res.data).split('\n');
        records.pop();
        this.pages = records.length > 0 ? parseInt(records[0].trim()) : 1;
        this.ranking = [];
        for (let i = 1; i < records.length; i++)
          this.ranking.push(records[i].trim().split(','));
        this.state = 'ok';
      }).catch((e) => {
        this.state = 'error';
        console.log(e);
      });
    }
  },
  watch: {
    page: function () {
      this.loadPage();
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
};
</script>
